<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-avatar size="45" :color="color" v-on="on" class="slaPointer">
        <p class="white--text headline my-auto">
          {{ number }}
        </p>
      </v-avatar>
    </template>

    <v-card>
      <v-toolbar dark color="#c4c22c">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-data-table
                :headers="headers"
                :items-per-page="10"
                class="elevation-1"
                :items="items"
              >
                <template v-slot:item.detalhes="{ item }">
                  <LeadDetail :lead="item" />
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import LeadDetail from "@/components/Lead_Detail";

export default {
  name: "Slas",
  props: {
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    color: {
      type: String,
    },
    number: {
      type: Number,
    },
  },
  components: { LeadDetail },

  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.slaPointer {
  cursor: pointer;
}
</style>