<template>
  <section class="home">
    <h2>O meu dia</h2>
    <v-row>
      <!-- AGENDA e Historico -->
      <v-col cols="7">
        <v-row>
          <v-col>
            <v-card class="py-5 px-5">
              <h3 class="mb-5">Agenda:</h3>

              <!-- CONTATO DE HOJE -->
              <section v-for="(item, k) in tablesData" :key="k" v-show="k == 0">
                <h4>{{ item.title }}</h4>
                <v-row>
                  <v-col>
                    <!-- TABELA DAS LEADS -->

                    <SimpleTab
                      :data="item.data"
                      :headers="item.headers"
                      :itemsPerPage="item.itemsPerPage"
                      :hideDefaultFooter="item.hideDefaultFooter"
                      :loaderDataTable="item.loaderDataTable"
                    />
                  </v-col>
                </v-row>
              </section>

              <!-- -------- tables Data -------- -->

              <v-expansion-panels accordion>
                <v-expansion-panel
                  v-for="(item, k) in tablesData"
                  :key="k"
                  v-show="k != 0"
                >
                  <v-expansion-panel-header
                    ><p class="ma-0">
                      {{ item.title }}
                    </p></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <!-- TABELA DAS LEADS -->
                    <v-row>
                      <v-col>
                        <SimpleTab
                          :data="item.data"
                          :headers="item.headers"
                          :itemsPerPage="item.itemsPerPage"
                          :hideDefaultFooter="item.hideDefaultFooter"
                          :loaderDataTable="item.loaderDataTable"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange" text to="/leads"> Ver Mais </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <!-- HISTORICO -->
        <v-row>
          <v-col>
            <v-card class="py-5 px-5">
              <h3>Histórico do Cliente:</h3>
              <!-- FIltro de Histórico -->
              <v-row>
                <v-col>
                  <v-text-field label="Email" v-model="email"></v-text-field>
                </v-col>
                <v-col
                  ><v-text-field label="NIF" v-model="nif"></v-text-field>
                </v-col>
                <v-col
                  ><v-text-field
                    label="Telefone"
                    v-model="telefone"
                  ></v-text-field>
                </v-col>
                <v-col class="text-right">
                  <v-btn color="rgb(196, 194, 44)" dark @click="searchClient()"
                    >Pesquisar</v-btn
                  >
                </v-col>
              </v-row>

              <!-- TABELA DAS LEADS -->

              <SimpleTab
                :data="leadsHistorico"
                :headers="headersSearch"
                :hideDefaultFooter="true"
                :itemsPerPage="5"
                :loaderDataTable="loaderSearchClient"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- SLAS e Objetivos -->
      <v-col cols="5">
        <!-- Objetivos -->
        <v-row>
          <!-- NOVAS LEADS -->
          <v-col>
            <v-card class="py-5" height="260px">
              <div class="text-center px-2">
                <h3>Novas Leads:</h3>
                <h1
                  :class="newLeads == 'A contar...' ? 'mt-5' : 'mt-5 h1-leads'"
                >
                  {{ newLeads }}
                </h1>
              </div>
              <!-- BTN CONTACTAR -->
              <v-card-actions v-if="!progress">
                <v-spacer></v-spacer>
                <v-btn
                  color="orange"
                  text
                  href="https://cloud7.go-contact.com/login.html"
                  target="_blank"
                >
                  Contactar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="mb-4 px-5 py-8">
              <h3 class="text-center">Objetivos:</h3>
              <h4 class="text-center mb-3">
                {{ new Date().getFullYear() }}-{{ new Date().getMonth() + 1 }}
              </h4>
              <v-row>
                <v-col class="text-center">
                  <h4 class="mb-2">Objetivo mensal:</h4>
                  <h3 class="my-auto">{{ objetivoConsultor }} €</h3>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="text-center">
                  <h4 class="mb-2">Valor atingido:</h4>
                  <v-progress-circular
                    :rotate="360"
                    :size="100"
                    :width="20"
                    :value="percentConsultor"
                    :color="color"
                  >
                    {{ percentConsultor }}%
                  </v-progress-circular>

                  <h3 class="mt-5">{{ atingidoConsultor }} €</h3>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- SLAS -->
        <v-row>
          <v-col>
            <v-card class="mb-4 py-8 px-12">
              <v-card-title> SLAS: </v-card-title>

              <div class="py-auto mx-auto text-center" v-if="progress">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>

              <v-row v-else>
                <v-col>
                  <v-row>
                    <v-col v-for="(item, i) in contentSlas" :key="i" cols="6">
                      <v-row>
                        <v-col>
                          <h4 class="text-center">{{ item.title }}:</h4>
                        </v-col>
                      </v-row>

                      <v-row class="px-8">
                        <v-col
                          :cols="4"
                          v-for="(number, j) in item.content"
                          :key="j"
                        >
                          <Slas
                            :items="number.items"
                            :headers="headersAgent"
                            :color="number.status"
                            :number="number.number"
                          />

                          <!-- <v-dialog
                            v-model="item.dialog[j]"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                          >
                            <template v-slot:activator="{ on }">
                              <v-avatar
                                size="45"
                                :color="number.status"
                                v-on="on"
                              >
                                <p class="white--text headline my-auto">
                                  {{ number.number }}
                                </p>
                              </v-avatar>
                            </template>

                            <v-card>
                              <v-toolbar dark color="#c4c22c">
                                <v-btn
                                  icon
                                  dark
                                  @click="item.dialog[j] = false"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title></v-toolbar-title>
                                <v-spacer></v-spacer>
                              </v-toolbar>

                              <v-container>
                                <v-row>
                                  <v-col>
                                    <v-card>
                                      <v-data-table
                                        :headers="headersAgent"
                                        :items-per-page="15"
                                        class="elevation-1"
                                        :items="desserts"
                                      >
                                      </v-data-table>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-dialog> -->
                          <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar
                                size="45"
                                :color="number.status"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <p class="white--text headline my-auto">
                                  {{ number.number }}
                                </p>
                              </v-avatar>
                            </template>
                           
                            <span>{{
                              item.tooltip[number.status.split(" ")[0]]
                            }}</span>
                          </v-tooltip> -->
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import SimpleTab from "@/components/SimpleTab";
import Slas from "@/components/Slas";

export default {
  name: "Dia",
  components: {
    SimpleTab,
    Slas,
  },
  data() {
    return {
      contentSlas: [
        {
          id: 1,
          title: "Aguarda Documentos",
          step_id: [13, 4, 63],
          status: [{ type: [1, 2, 3], green: 3, yellow: 10 }], //maximo de número de dias
          total: 0,
          content: [],
          dialog: {
            0: false,
            1: false,
            2: false,
          },
          items: {
            0: [],
            1: [],
            2: [],
          },
          tooltip: {
            green: "Até 3 dias",
            amber: "De 3 a 10 dias",
            red: "Mais de 10 dias",
          },
        },
        {
          id: 2,
          title: "Aguarda Decisão de Parceiros",
          step_id: [20, 7],
          status: [
            { type: [1], green: 1, yellow: 3 },
            { type: [2], green: 3, yellow: 7 },
          ],
          total: 0,
          dialog: {
            0: false,
            1: false,
            2: false,
          },
          items: {
            0: [],
            1: [],
            2: [],
          },
          content: [24, 26, 29],
          tooltip: {
            green: "Até 3 dias",
            amber: "De 3 a 10 dias",
            red: "Mais de 10 dias",
          },
        },
        {
          id: 3,
          title: "Aguarda Decisão do Cliente",
          step_id: [22],
          dialog: {
            0: false,
            1: false,
            2: false,
          },
          items: {
            0: [],
            1: [],
            2: [],
          },
          status: [
            { type: [1], green: 3, yellow: 7 },
            { type: [2], green: 5, yellow: 15 },
          ],
          total: 0,
          content: [],
          tooltip: {
            green: "Até 3 dias para CH e CC até 5 dias",
            amber: "De 3 a 10 dias para CH e CC de 5 a 15 dias",
            red: "Mais de 10 dias para CH e mais de 15 dias para CC",
          },
        },
        {
          id: 4,
          title: "Aguarda Devolução do Contrato",
          step_id: [66],
          dialog: {
            0: false,
            1: false,
            2: false,
          },
          items: {
            0: [],
            1: [],
            2: [],
          },
          status: [{ type: [1, 2, 3], green: 1, yellow: 3 }],
          total: 0,
          content: [],
          tooltip: {
            green: "Até 1 dia",
            amber: "De 1 a 3 dias",
            red: "Mais de 3 dias",
          },
        },
      ],
      username: window.sessionStorage.getItem("username"),
      moment: require("moment"),
      newLeads: "A contar...",
      progress: true,
      objetivoConsultor: 0,
      percentConsultor: 0,
      color: "light-blue lighten-5",
      atingidoConsultor: 0,

      //histórico:
      email: "",
      nif: "",
      telefone: "",
      leadsHistorico: [],
      headersAgent: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        // { text: 'Data', align: 'center', value: 'dt_created', sortable:true },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        // { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        {
          text: "Último Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Agendamento",
          align: "center",
          value: "callback_date",
          sortable: true,
        },
        {
          text: "Estado",
          align: "center",
          value: "current_step",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
      ],

      headersEscritura: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        // { text: 'Data', align: 'center', value: 'dt_created', sortable:true },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        // { text: 'Prazo', align: 'center', value: 'aux_2', sortable:true },
        {
          text: "Último Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Data da Escritura",
          align: "center",
          value: "data_da_escritura",
          sortable: true,
        },
        {
          text: "Hora da Escritura",
          align: "center",
          value: "hora_da_escritura",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
      ],

      headersSearch: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        // { text: 'Data', align: 'center', value: 'dt_created', sortable:true },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        // { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        {
          text: "Último Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Produto",
          align: "center",
          value: "produto",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
      ],

      loader: false,

      //AGENDA:

      //Callbacks:
      missedCallbacks: [],
      futureCallbacks: [],
      contactToday: [],
      loaderLead: false,
      leadsDistrate: [],
      leadsEscritura: [],

      dialog: false,

      desserts: [
        {
          lead_process_id: "8007",
          process_id: "3",
          lead_id: "23975",
          produto: "seguro_de_vida",
          nome: "João Nogueira ",
          email: "jjanogueira@gmail.com",
          aux_1: "75000_100000",
          telefone: "917819204",
          aux_2: null,
          dt_created: "2021-01-28 16:05:02",
          current_step: "SS - Nova Lead",
          current_step_id: "35",
          username: null,
          contact_id: null,
          last_interation: "2021-01-28 16:05:02",
          data_inicio_processo: "2021-01-28 16:05:02",
          lead_details: [],
          observations: null,
          prit_data: {
            titulo: "",
            nome: "",
            estado_civil: "",
            data_nascimento: "",
            telefone: "",
            email: "",
            nacionalidade: "",
            naturalidade: "",
            tipo_de_documento: "",
            numero_do_documento: "",
            validade_do_documento: "",
            nif: "",
            dependentes: "",
            entidade_patronal: "",
            empregado_desde: "",
            vinculo: "",
            profissao: "",
            sector: "",
            rendimento_liquido_mensal: "",
            rendimentos_variaveis: "",
            rendimentos_prediais: "",
            pensao_de_alimentos: "",
            rendimentos_nao_comprovaveis: "",
            outros_rendimentos: "",
            proveniencia_de_outros_rendimentos: "",
            iban: "",
            ano_de_abertura_da_conta: "",
            tipo_de_habitacao: "",
            morada: "",
            codigo__postal: "",
            localidade: "",
            vive_nesta_casa_desde: "",
          },
          sect_data: {
            titulo: "",
            nome: "",
            estado_civil: "",
            data_nascimento: "",
            telefone: "",
            email: "",
            nacionalidade: "",
            naturalidade: "",
            tipo_de_documento: "",
            numero_do_documento: "",
            validade_do_documento: "",
            nif: "",
            dependentes: "",
            entidade_patronal: "",
            empregado_desde: "",
            vinculo: "",
            profissao: "",
            sector: "",
            rendimento_liquido_mensal: "",
            rendimentos_variaveis: "",
            rendimentos_prediais: "",
            pensao_de_alimentos: "",
            rendimentos_nao_comprovaveis: "",
            outros_rendimentos: "",
            proveniencia_de_outros_rendimentos: "",
            iban: "",
            ano_de_abertura_da_conta: "",
          },
          operation_data: {
            operacao: "",
            prazo_pretendido: "",
            montante_a_consolidar___financiar: "",
            adesao_ao_seguro: "",
            liquidez_adicional: "",
          },
          responsabilities: {
            renda___prestacao_ch_1t: "",
            pensao_de_alimentos_1t: "",
            renda___prestacao_ch_2t: "",
            pensao_de_alimentos_2t: "",
            resp: [],
          },
          banco: null,
          avaliacao: null,
          escritura: null,
          seguros: null,
          owner: null,
          comercial_assessment: null,
          callback_date: null,
        },
        {
          lead_process_id: "8006",
          process_id: "1",
          lead_id: "23974",
          produto: "credito_pessoal",
          nome: "Maria Gonçalves",
          email: "elizabet.goncalves@gmail.com",
          aux_1: "5000",
          telefone: "910458291",
          aux_2: "7",
          dt_created: "2021-01-28 15:54:02",
          current_step: "CC - Lead",
          current_step_id: "3",
          username: null,
          contact_id: null,
          last_interation: "2021-01-28 15:54:02",
          data_inicio_processo: "2021-01-28 15:54:02",
          lead_details: [],
          observations: null,
          prit_data: {
            titulo: "",
            nome: "",
            estado_civil: "",
            data_nascimento: "",
            telefone: "",
            email: "",
            nacionalidade: "",
            naturalidade: "",
            tipo_de_documento: "",
            numero_do_documento: "",
            validade_do_documento: "",
            nif: "",
            dependentes: "",
            entidade_patronal: "",
            empregado_desde: "",
            vinculo: "",
            profissao: "",
            sector: "",
            rendimento_liquido_mensal: "",
            rendimentos_variaveis: "",
            rendimentos_prediais: "",
            pensao_de_alimentos: "",
            rendimentos_nao_comprovaveis: "",
            outros_rendimentos: "",
            proveniencia_de_outros_rendimentos: "",
            iban: "",
            ano_de_abertura_da_conta: "",
            tipo_de_habitacao: "",
            morada: "",
            codigo__postal: "",
            localidade: "",
            vive_nesta_casa_desde: "",
          },
          sect_data: {
            titulo: "",
            nome: "",
            estado_civil: "",
            data_nascimento: "",
            telefone: "",
            email: "",
            nacionalidade: "",
            naturalidade: "",
            tipo_de_documento: "",
            numero_do_documento: "",
            validade_do_documento: "",
            nif: "",
            dependentes: "",
            entidade_patronal: "",
            empregado_desde: "",
            vinculo: "",
            profissao: "",
            sector: "",
            rendimento_liquido_mensal: "",
            rendimentos_variaveis: "",
            rendimentos_prediais: "",
            pensao_de_alimentos: "",
            rendimentos_nao_comprovaveis: "",
            outros_rendimentos: "",
            proveniencia_de_outros_rendimentos: "",
            iban: "",
            ano_de_abertura_da_conta: "",
          },
          operation_data: {
            operacao: "",
            prazo_pretendido: "",
            montante_a_consolidar___financiar: "",
            adesao_ao_seguro: "",
            liquidez_adicional: "",
          },
          responsabilities: {
            renda___prestacao_ch_1t: "",
            pensao_de_alimentos_1t: "",
            renda___prestacao_ch_2t: "",
            pensao_de_alimentos_2t: "",
            resp: [],
          },
          banco: null,
          avaliacao: null,
          escritura: null,
          seguros: null,
          owner: null,
          comercial_assessment: null,
          callback_date: null,
        },
      ],
    };
  },
  methods: {
    collectCallbacks: async function () {
      this.loaderLead = true;
      let today = this.moment().format("L");
      let week = this.moment().week(); //semana atual;
      await this.$store.dispatch("collect_my_callbacks", this.username);

      this.missedCallbacks = this.$store.state.leadsCallbacks[0].items.map(
        (e) => ({
          lead_process_id: e.lead_process_id,
          nome: e.nome,
          email: e.email,
          telefone: e.telefone,
          aux_1: e.aux_1,
          last_interation: e.last_interation,
          current_step: e.current_step,
          callback_date: e.callback_date,
          detalhes: e,
        })
      );

      this.futureCallbacks = this.$store.state.leadsCallbacks[1].items;

      this.contactToday = this.futureCallbacks
        .filter((e) => this.moment(e.callback_date).format("L") == today)
        .map((e) => ({
          lead_process_id: e.lead_process_id,
          nome: e.nome,
          email: e.email,
          telefone: e.telefone,
          aux_1: e.aux_1,
          last_interation: e.last_interation,
          current_step: e.current_step,
          callback_date: e.callback_date,
          detalhes: e,
        }));

      this.futureCallbacks = this.futureCallbacks.map((e) => ({
        lead_process_id: e.lead_process_id,
        nome: e.nome,
        email: e.email,
        telefone: e.telefone,
        aux_1: e.aux_1,
        last_interation: e.last_interation,
        current_step: e.current_step,
        callback_date: e.callback_date,
        detalhes: e,
      }));

      //Data Limite de pedido de distrate:
      let leads = this.$store.state.leads_enabled;

      this.leadsDistrate = leads
        .filter((e) => e.current_step_id == "27")
        .map((e) => ({
          lead_process_id: e.lead_process_id,
          nome: e.nome,
          email: e.email,
          telefone: e.telefone,
          aux_1: e.aux_1,
          last_interation: e.last_interation,
          data_da_escritura: e.escritura.data_da_escritura,
          hora_da_escritura: e.escritura.hora_da_escritura,
          detalhes: e,
        }));

      //Leads com escritura Marcada nesta semana;
      this.leadsEscritura = leads
        .filter(
          (e) =>
            e.current_step_id == "25" &&
            this.moment(e.escritura.data_da_escritura).week() == week
        )
        .map((e) => ({
          lead_process_id: e.lead_process_id,
          nome: e.nome,
          email: e.email,
          telefone: e.telefone,
          aux_1: e.aux_1,
          last_interation: e.last_interation,
          data_da_escritura: e.escritura.data_da_escritura,
          hora_da_escritura: e.escritura.hora_da_escritura,
          detalhes: e,
        }));

      this.loaderLead = false;
    },
    collectLeads: async function () {
      await this.$store.dispatch("my_leads_in_process_new", this.username);
      await this.$store.dispatch("leads_disabled_consultor", this.username);

      //Coletar os dados para os números;
      this.collectData();
    },
    collectNewLeadsTotal: async function () {
      await this.$store.dispatch("collect_new_leads_total");
      const dadosNewLeads = this.$store.state.newLeadsTotal;

      const dNewLeads = Object.keys(dadosNewLeads).map((i) => dadosNewLeads[i]);

      this.newLeads = dNewLeads[0];
    },
    collectData() {
      let dados = this.$store.state.leads_enabled;
      let today = this.moment();

      //Coletar os dados para cada SLA's:
      for (let i in this.contentSlas) {
        let number = dados.filter((e) =>
          this.contentSlas[i].step_id.includes(Number(e.current_step_id))
        );

        let greenItems = [];
        let yellowItems = [];
        let redItems = [];

        let greenColor = 0;
        let yellowColor = 0;
        let redColor = 0;

        for (let j in this.contentSlas[i].status) {
          number = number.filter((e) =>
            this.contentSlas[i].status[j].type.includes(Number(e.process_id))
          );

          greenItems = greenItems.concat(
            number.filter(
              (e) =>
                Number(today.diff(this.moment(e.last_interation), "days")) <
                this.contentSlas[i].status[j].green
            )
          );

          greenColor = greenItems.length;

          yellowItems = yellowItems.concat(
            number.filter(
              (e) =>
                Number(today.diff(this.moment(e.last_interation), "days")) >=
                  this.contentSlas[i].status[j].green &&
                Number(today.diff(this.moment(e.last_interation), "days")) <
                  this.contentSlas[i].status[j].yellow
            )
          );

          yellowColor = yellowItems.length;

          redItems = redItems.concat(
            number.filter(
              (e) =>
                Number(today.diff(this.moment(e.last_interation), "days")) >=
                this.contentSlas[i].status[j].yellow
            )
          );

          redColor = redItems.length;
        }

        this.contentSlas[i].content = [
          {
            status: "green lighten-2",
            number: greenColor,
            items: greenItems,
          },
          {
            status: "amber lighten-4",
            number: yellowColor,
            items: yellowItems,
          },
          {
            status: "red lighten-2",
            number: redColor,
            items: redItems,
          },
        ];

        this.contentSlas[i].total = greenColor + yellowColor + redColor;
      }
    },
    async collectGoal() {
      //await this.$store.dispatch("collect_my_goal", this.username);

      await this.$store.dispatch("collect_my_goal", this.username);
      this.objetivoConsultor = this.$store.state.consultantGoal;

      //Buscar todos as leads que estão em estado financiado deste mês:
      let actualMonth = this.moment().month();
      let financiado = ["18", "11", "40"];

      let dados = this.$store.state.leads_disabled;

      const reducer = (accumulator, currentValue) => accumulator + currentValue;

      dados = dados
        .filter(
          (e) =>
            this.moment(e.last_interation).month() == actualMonth &&
            financiado.includes(e.current_step_id)
        )
        .map((e) => Number(e.aux_1));

      if (dados.length > 0) {
        dados = dados.reduce(reducer);
        this.atingidoConsultor = dados;
      } else dados = 0;

      //Comparar com o que foi de objetivo para esse consultor:
      this.percentConsultor = Math.floor(
        (dados / this.objetivoConsultor) * 100
      );

      //Buscar por cor o valor do objetivo:
      this.color = this.setColor(this.percentConsultor);
    },
    setColor(value) {
      if (value < 30) {
        return "light-blue lighten-3";
      } else if (value >= 30 && value < 60) {
        return "cyan lighten-2";
      } else if (value >= 60 && value < 80) {
        return "teal lighten-2";
      } else {
        return "teal darken-3";
      }
    },
    async searchClient() {
      //Api Body
      let apiBody = {
        email: this.email,
        nif: this.nif,
        telefone: this.telefone,
      };

      await this.$store.dispatch("collect_history", apiBody);

      this.loader = true;

      //
      let data = this.$store.state.historyClient.filter(
        (e) => e.telefone != "0" && e.telefone != null
      );

      this.leadsHistorico = data.map((e) => ({
        lead_process_id: e.id,
        nome: e.nome,
        email: e.email,
        telefone: e.telefone,
        aux_1: e.aux_1,
        last_interation: e.last_update_dt,
        current_step: e.current_step,
        produto: e.produto,
        detalhes: e,
      }));

      this.loader = false;
    },
    async prepareView() {
      await this.collectLeads();
      await this.collectNewLeadsTotal();
      await this.collectGoal();
      await this.collectCallbacks();

      this.progress = false;
    },
  },
  async created() {
    await this.prepareView();
  },
  computed: {
    loaderTableCallbacks() {
      return this.loaderLead;
    },
    loaderSearchClient() {
      return this.loader;
    },
    tablesData() {
      return [
        {
          title: "Contactos de hoje",
          data: this.contactToday,
          headers: this.headersAgent,
          itemsPerPage: 3,
          hideDefaultFooter: true,
          loaderDataTable: this.loaderTableCallbacks,
        },
        {
          title: "Escrituras da Semana",
          data: this.leadsEscritura,
          headers: this.headersEscritura,
          itemsPerPage: 3,
          hideDefaultFooter: true,
          loaderDataTable: this.loaderTableCallbacks,
        },
        {
          title: "Data limite de pedido de distrate",
          data: this.leadsDistrate,
          headers: this.headersEscritura,
          itemsPerPage: 3,
          hideDefaultFooter: true,
          loaderDataTable: this.loaderTableCallbacks,
        },
        {
          title: "Próximos Callbacks",
          data: this.futureCallbacks,
          headers: this.headersAgent,
          itemsPerPage: 3,
          hideDefaultFooter: false,
          loaderDataTable: this.loaderTableCallbacks,
        },
        {
          title: "Callbacks Perdidos",
          data: this.missedCallbacks,
          headers: this.headersAgent,
          itemsPerPage: 3,
          hideDefaultFooter: false,
          loaderDataTable: this.loaderTableCallbacks,
        },
      ];
    },
  },
};
</script>

<style scoped>
.home {
  width: calc(100vw - 256px);
  margin-left: 256px;
  padding: 40px;
  padding-top: 50px;
  margin-bottom: 50px;
}
h1 {
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}
.circle-number {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-content: center;
}

.h1-leads {
  font-size: 55px;
}
</style>